@use "../../config/veriable.scss" as global;

.section-2 {
  width: 100%;
  height: 150vh;
  background-size: cover;
  position: relative;
  z-index: 999;
  overflow: hidden;
  @media (max-width: 576px) {
    height: 85vh;
  }
}
.section-2-video {
  height: 100%;
  width: 100%;
  object-fit: fill;
  @media (max-width: 768px) {
    object-fit: cover;
  }
}

.sectin2-div {
  width: 100%;
  height: 150vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 10%;
  padding-top: 75px;
  @media screen and (min-width: 576px) and (max-width: 992px) {
    top: 10%;
  }
  @media screen and (max-width: 576px) {
    height: 100vh;
    width: 100%;
    top: 10%;
    padding-top: 20px !important;
    padding-bottom: 10px;
  }
}
.heading-div {
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    font-size: 3rem;
    color: #f5d568 !important;
    font-family: global.$Bold2;
    line-height: 4rem;

    @media screen and (min-width: 576px) and (max-width: 768px) {
      font-size: 2.5rem;
      line-height: 0rem;
    }
    @media (max-width: 576px) {
      font-size: 1.25rem !important;
      line-height: 0rem;
    }
  }
}
.main-img1 {
  width: 100%;
  height: 660px;
  object-fit: cover;
  @media screen and (min-width: 768px) and (max-width: 992px) {
    height: 575px;
  }
  @media (max-width: 768px) {
    display: none;
  }
}
.text-div {
  width: 100%;
  h4 {
    font-size: 24px;
    color: #f5d568 !important;
    font-weight: 400;
    font-family: global.$Medium;
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
  @media (max-width: 576px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}
p {
  font-size: 14px;
  font-weight: 400;
  color: white;
  font-family: global.$Regular2;
  @media screen and (max-width: 576px) {
    font-size: 12px;
  }
}
.deskPicture-section-2 {
  display: block;
  @media screen and (max-width: 576px) {
    display: none;
  }
}

.last-text {
  font-family: global.$Regular2;
  font-size: 13px;
  color: white;
  display: flex;
  align-items: center;
  @media screen and (max-width: 576px) {
    font-size: 12px;
  }
}

.defult-btn-section-2 {
  width: 12.4rem;
  height: 51px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #f5d568;
  background-color: #f5d46857;
  font-family: global.$Regular2;
  color: white;
  cursor: pointer;
  height: 50px;
  @media screen and (min-width: 768px) and (max-width: 992px) {
    width: 10rem;
  }
}
.defult-btn-section-2::before {
  background-color: rgba(245, 212, 104, 0.5);
  filter: blur(500px);
  border-radius: 8px;
  z-index: -1;
  height: 50px;
}
.defult-btn-section-2:hover {
  background-color: #f5d568;
  color: black;
}
