@use "../../config/veriable.scss" as global;

.section-4 {
  width: 100%;
  height: 120vh;
  position: relative;
  background-size: cover;
  opacity: 100%;
  z-index: 1;
  // @media screen and (min-width: 576px) and (max-width: 992px) {
  //   height: 110vh;
  // }
  @media screen and (min-width: 576px) and (max-width: 768px) {
    height: 100vh;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    height: 170vh;
  }
  @media (max-width: 576px) {
    video {
      display: none;
    }
    background-color: #000;
    height: 100vh;
  }
}
.section-4-video {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  // @media screen and (min-width: 576px) and (max-width: 992px) {
  //   height: 120vh !important;
  //   object-fit: fill !important;
  // }
  @media screen and (min-width: 576px) and (max-width: 768px) {
    height: 100vh !important;
    object-fit: fill !important;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    height: 170vh !important;
    object-fit: fill !important;
  }
  @media (max-width: 576px) {
    object-fit: fill !important;
    height: 100% !important;
    backdrop-filter: blur(5px);
  }
}
.content-sub-divs-Desk {
  display: block !important;
  display: flex !important;
  @media (max-width: 768px) {
    display: none !important;
  }
}
.content-sub-divs-Mob {
  display: none !important;
  @media (max-width: 768px) {
    display: block !important;
    color: #fff;
  }
}

.Horizontal_PscrollBox {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
}
.Horizontal_PscrollBox::-webkit-scrollbar {
  display: none;
}
.Horizontal_item {
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: end;
  width: 90%;
  height: 330px;
  @media screen and (min-width: 576px) and (max-width: 992px) {
    width: 100%;
  }
}

.section4-bg {
  width: 100%;
  height: 150vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  position: absolute;
  top: 8%;
  @media screen and (max-width: 768px) {
    height: 100vh;
    width: 100%;
    justify-content: center;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    top: 7%;
  }

}
.heading-textt {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  h3 {
    font-size: 3rem;
    color: #f5d568 !important;
    font-family: global.$Bold2;
    line-height: 4rem;

    @media screen and (min-width: 576px) and (max-width: 768px) {
      font-size: 2.5rem;
      line-height: 0rem;
    }
    @media (max-width: 576px) {
      font-size: 1.25rem !important;
      line-height: 0rem;
    }
  }
}
.content-sub-divs {
  width: 100%;
  // min-height: 400px;
  // max-height: 600px;
  background-color: black;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  img {
    width: 100%;
    // height: 300px;
    min-height: 300px;
    object-fit: cover;
    @media screen and (min-width: 576px) and (max-width: 991px) {
      height: 200px;
    }
  }
}

.content-sub-divs:hover {
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
  border: 1px solid #f5d568;
}

.section4-btn {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.content-txt1 {
  font-size: 16px;
  color: #fff;
  font-family: global.$Medium;
  background-color: #00000079;
  width: 100%;
  text-align: center;
  @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    margin-bottom: 0px;
    font-size: 16px;
    padding: 10px 10px 0px;
  }
}
.content-txt2 {
  text-align: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  color: #e3e2e2;
  font-family: global.$Regular2;
  padding: 0px 20px;
  margin-bottom: 20px;
  background-color: #00000079;
  white-space: normal; /* Ensures text will wrap */
  @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 12px;
  }
  @media (max-width: 768px) {
    margin-bottom: 0px;
    font-size: 12px;
    padding: 10px;
  }
}
