@font-face {
  font-family: "CinzelDecorative-Regular";
  src: url("./assets/font/CinzelDecorative-Black.ttf") format("truetype");
}
@font-face {
  font-family: "CinzelDecorative-Bold";
  src: url("./assets/font/CinzelDecorative-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "CinzelDecorative-Black";
  src: url("./assets/font/CinzelDecorative-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway-Italic-VariableFont_wght";
  src: url("./assets/font/Raleway-Italic-VariableFont_wght.ttf")
    format("truetype");
}
@font-face {
  font-family: "DMSans-Bold";
  src: url("./assets/font/DMSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-SemiBold";
  src: url("./assets/font/DMSans-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "IsidoraSans-Bold";
  src: url("./assets/font/IsidoraSans-Bold.ttf") format("truetype");
  ascent-override: 90%;
  descent-override: 10%;
  line-gap-override: 0%;
}

@font-face {
  font-family: "IsidoraSans-SemiBold";
  src: url("./assets/font/IsidoraSans-SemiBold.ttf") format("truetype");
  ascent-override: 90%;
  descent-override: 10%;
  line-gap-override: 0%;
}

@font-face {
  font-family: "IsidoraSans-Medium";
  src: url("./assets/font/IsidoraSans-Medium.ttf") format("truetype");
  ascent-override: 90%;
  descent-override: 10%;
  line-gap-override: 0%;
}
@font-face {
  font-family: "IsidoraSans-Light";
  src: url("./assets/font/IsidoraSans-Light.ttf") format("truetype");
  ascent-override: 90%;
  descent-override: 10%;
  line-gap-override: 0%;
}
@font-face {
  font-family: "IsidoraSans-Regular";
  src: url("./assets/font/IsidoraSans-Regular.ttf") format("truetype");
  ascent-override: 90%;
  descent-override: 10%;
  line-gap-override: 0%;
}
