@use "../../config/veriable.scss" as global;

.section-video {
  width: 100%;
  height: 120vh;
  position: relative;
  z-index: 999;
  font-size: 3rem;
  background-size: cover;
  overflow: hidden;
  @media (max-width: 576px) {
    height: 100vh;
  }
}
.section-video-play {
  height: 100%;
  width: 100%;
  object-fit: fill;
  @media (max-width: 768px) {
    object-fit: cover;
  }
}
.section-video-bg {
  width: 60%;
  height: 100vh;
  position: absolute;
  top: 25%;
  right: 20%;
  transform: translate(-0%, -0%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h4 {
    font-family: global.$SemiBold;
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
    width: 100%;
    line-height: 2.5rem;
    text-align: center;
    @media screen and (max-width: 576px) {
      font-size: 0.75rem;
      font-weight: 0;
      line-height: 1rem;
    }
  }
  @media screen and (max-width: 576px) {
    width: 100%;
    right: 0%;
    top: 15%;
  }
}
.text-white {
  font-family: global.$Bold2;
  text-align: center;
  font-size: 3.5rem;
  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
}
.Contact-Div {
  position: absolute;
  top: 87%;
  width: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 15%;
  padding-right: 30px;
}
.defult-btn-section-video {
  width: 12.4rem;
  height: 51px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #f5d568;
  background-color: #f5d46857;
  font-family: global.$Regular2;
  color: white;
  cursor: pointer;

  @media screen and (max-width: 576px) {
    width: 10.4rem;
    height: 41px;
  }
}
.default-btn::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(245, 212, 104, 0.5);
  filter: blur(500px);
  border-radius: 8px;
  z-index: -1;
}
.defult-btn:hover {
  background-color: #f5d568;
  color: black;
}
