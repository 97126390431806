@use "../../config/veriable.scss" as global;

.section-1 {
  height: 130vh;
  position: relative;
  z-index: 999;
  background-size: cover;
  @media screen and (max-width: 768px) {
    height: 100vh;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    height: 120vh;
  }

}
.section-1-Video {
  height: 100%;
  width: 100%;
  object-fit: fill;
  @media (max-width: 768px) {
    object-fit: cover;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    height: 120vh;
  }
}
.section1-secondDiv {
  position: absolute;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 5%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  height: 20%;
  top: 0%;
}
.section1-bg {
  width: 100%;
  height: 150vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  position: absolute;
  top: 0%;
  @media screen and (max-width: 576px) {
    height: 100vh;
    width: 100%;
  }
}
.heading-div1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  z-index: 999;
  @media (max-width: 576px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.heding-text {
  font-size: 3rem;
  color: #f5d568 !important;
  font-family: global.$Bold2;
  line-height: 4rem;
  @media screen and (min-width: 576px) and (max-width: 768px) {
    font-size: 2.5rem;
    line-height: 0rem;
  }
  @media (max-width: 576px) {
    font-size: 1.25rem !important;
    line-height: 0rem;
  }
}

.EmtyDiv {
  height: 75px;
  @media (max-width: 768px) {
    height: 50px;
  }
}

.ornamans-mai-div {
  width: 100%;
  height: 25vh;
  display: flex;
  @media (max-width: 576-x) {
  }
}

.ornamans {
  width: 100%;
  display: flex;
  align-items: center;
}
.ornamans:hover {
  color: #f5d568;
}

.ornamans-1:hover {
  transition: all 0.1s ease;
  opacity: 1;
  transform: scale(1.09);
}
.ornamans-imgg {
  width: 100%;
  margin-top: 75px;
  display: flex;
  justify-content: space-evenly;
  @media (max-width: 576px) {
    display: none;
  }
}
.oranamens-img {
  width: 100% !important;
  object-fit: fill;
}

.custemize-btn-section-1 {
  width: 12.4rem;
  height: 51px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #f5d568;
  background-color: #f5d46857;
  font-family: global.$Regular2;
  color: white;
  cursor: pointer;

  @media screen and (max-width: 576px) {
    width: 10.4rem;
    height: 41px;
  }
}
.custemize-btn-section-1::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(245, 212, 104, 0.5);
  filter: blur(500px);
  border-radius: 8px;
  z-index: -1;
}
.custemize-btn-section-1:hover {
  background-color: #f5d568;
  color: black;
}
