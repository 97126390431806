@use "../../config/veriable.scss" as global;

.hidden {
  transform: translateY(-100%); // Slide the header out of view
  transition: transform 0.3s ease; // Smooth transition for hiding
}

#navebarBox {
  background-color: #00000026;
  transition: transform 0.3s ease; // Smooth transition for showing
}
.head-text-color {
  color: white !important;
  @media screen and (max-width: 576px) {
    display: none;
  }
}

.head-text-color:hover {
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  @media screen and (max-width: 576px) {
    display: none;
  }
}
.navbar-logo {
  width: 200px;
}
.nav-link {
  font-family: global.$Regular2;
}
.nav-link:hover {
  // background-color: #f5d568 !important;
  color: #f5d568 !important;
}
.bg-lg-dark {
  @media (max-width: 992px) {
    background-color: rgb(25, 25, 25);
  }
}
.toggleBar-show {
  backdrop-filter: blur(10px);
}
