$Regular: "CinzelDecorative-Regular";
$Bold: "CinzelDecorative-Bold";
$Black: "CinzelDecorative-Black";
$RegularRale: "Raleway-Italic-RegularRale";

$SemiBold: "DMSans-SemiBold";
$DmBold: "DMSans-Bold";

$Light: "IsidoraSans-Light";
$Regular2: "IsidoraSans-Regular";
$Medium: "IsidoraSans-Medium";
$SemiBold: "IsidoraSans-SemiBold";
$Bold2: "IsidoraSans-Bold";
