@use "../../config/veriable.scss" as global;

.section-3 {
  width: 100%;
  height: 110vh;
  background-size: cover;
  position: relative;
  z-index: 999;
  overflow: hidden;
  @media (max-width: 576px) {
    height: 80vh;
  }
  @media screen and (min-width: 576px) and (max-width: 768px) {
    height: 100vh !important;
  }
}
.section-3-Video {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  // @media screen and (min-width: 576px) and (max-width: 992px) {
  //   height: 130vh !important;
  //   object-fit: fill !important;
  // }
  @media screen and (min-width: 576px) and (max-width: 768px) {
    height: 100vh !important;
    object-fit: fill !important;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    height: 130vh !important;
    object-fit: fill !important;
  }
  @media (max-width: 576px) {
    object-fit: fill !important;
    height: 80vh !important;
    backdrop-filter: blur(5px);
  }
}

.sectin3-div {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  position: absolute;
  top: 15%;
  @media (max-width: 576px) {
    padding-left: 16px;
    top: 10%;
  }
  @media (max-width: 768px) {
    padding-left: 16px;
    top: 10%;
  }
}
.invest-btn {
  width: 100%;
}
.defult-btn {
  width: 12.4rem;
  height: 51px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #f5d568;
  background-color: #f5d46857;
  font-family: global.$Regular2;
  color: white;
  cursor: pointer;
}
.sectin3-div-Image {
  display: flex;

  @media screen and (max-width: 992px) {
    display: none;
  }
}
.sectin3-Image {
  width: 100%;
  object-fit: fill;
  height: 300px;
  margin-bottom: 30px;
}
.sectin3-Image2 {
  width: 100%;
  object-fit: fill;
  height: 200px;
}
.default-btn::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(245, 212, 104, 0.5);
  filter: blur(500px);
  border-radius: 8px;
  z-index: -1;
}
.defult-btn:hover {
  background-color: #f5d568;
  color: black;
}
.Content-Txt1 {
  font-size: 3rem;
  color: #f5d568 !important;
  font-family: global.$Bold2;
  line-height: 4rem;
  @media screen and (min-width: 576px) and (max-width: 768px) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
  @media (max-width: 576px) {
    font-size: 1.25rem !important;
    line-height: 1.7rem;
  }
}
.Content-Txt2 {
  font-family: global.$SemiBold;
  font-size: 1.5rem;
  color: #f5d568 !important;
  line-height: 4rem;
  @media (max-width: 576px) {
    font-size: 1rem;
    line-height: 1.7rem;
  }
}
