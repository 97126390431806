@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gruppo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  background-color: black !important;
  font-family: "Roboto", sans-serif !important;
  cursor: url("../public/card-img1.png"), auto; /* Replace with your cursor image */
}

.home-btn {
  background-color: #f5d568;
  border: none;
  z-index: 9;
  font-size: 1rem;
  padding-block: 0.9rem;
  padding-inline: 2rem;
  transition: box-shadow 0.2s ease;
  @media (max-width: 992px) {
    padding: 0.8rem;
  }
  &:hover {
    box-shadow: 0 -7px 10px #f5d46885,
      /* Top shadow */ 0 -1px 5px rgba(0, 0, 0, 0.08);
  }
}
.section-padding {
  padding-block: 10rem;
}

.w-90 {
  width: 90% !important;
}
.common-txt-font {
  font-family: "Gruppo", sans-serif;
}
@font-face {
  font-family: 'CinzelDecorative-Regular';
  src: url('./assets/font/CinzelDecorative-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'CinzelDecorative-Bold';
  src: url('./assets/font/CinzelDecorative-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'CinzelDecorative-Black';
  src: url('./assets/font/CinzelDecorative-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-Italic-RegularRale';
  src: url('./assets/font/static/Raleway-Regular.ttf') format('truetype');
}
