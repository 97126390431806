@use "../../config/veriable.scss" as global;

.footer {
  // background: #411e068c;
  background: #241003;
  position: relative;
  z-index: 2; 
  @media (max-width: 576px) {
    padding-left: 16px !important;
    padding-right: 16px;
  }
}
.custom-div-Head {
  color: #fff;
  font-size: 13px;
  font-family: global.$Medium;
}
.custom-div {
  color: #fff;
  z-index: 9;
  font-size: 16px;
  font-weight: 0 !important;
  font-family: global.$Regular2;
  text-decoration: none;
}
.footer-container {
  margin-top: 25px;
}

.list-container {
  z-index: 1;
  @media screen and (max-width: 576px) {
    margin-bottom: 20px;
  }
}
.list-heading {
  font-size: 20px;
  font-family: global.$SemiBold;
  font-weight: 700;
  color: #fff;
  padding-bottom: 12px;
  margin-bottom: 10px;
}
.list-container ul {
  list-style-type: none;
  padding-left: 0px;
}
.list-container ul li {
  font-family: global.$SemiBold;
  margin-bottom: 10px;
  font-size: 14px;
}
.listFooter-logo {
  object-fit: contain;
  width: 200px !important;
}
.Payment-cards {
  display: flex;
  gap: 30px;
  @media screen and (max-width: 576px) {
    gap: 15px;
  }
}
.social-cards {
  display: flex;
  width: 90%;
  gap: 15px;
  margin-top: 30px;

  @media screen and (max-width: 576px) {
    margin-bottom: 10px;
  }
}
.social-cards-Box {
  width: 30px;
  height: 30px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  z-index: 1000;
}
.social-cards-Box:hover {
  width: 30px;
  height: 30px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #fff;
  border: none;
  color: #000;
}

// FINAL FOOTER

.final-footer {
  border-top: 0.5px solid #e0e0e0;
  margin-top: 22px;
}
.final-footer-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  @media screen and (max-width: 576px) {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
}
.copyright-txt {
  font-family: global.$Regular2;
  font-size: 16px;
  color: #fff;
  @media screen and (max-width: 576px) {
    font-size: 12px;
  }
}
.custom-link {
  color: black;
  text-decoration: none;
}

